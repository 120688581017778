<script setup lang="ts">
    import { type Betriebspruefung, type LegeBetriebspruefungsterminFestRequest } from '@/js/model'
    import { useBetriebspruefungService } from '@/services'
    import { formatDateTimeNoSeconds } from '@/utils'
    import { ref, onMounted, watch, computed, defineEmits } from 'vue'
    import dayjs from 'dayjs'
    import { de } from 'date-fns/locale'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const service = useBetriebspruefungService()

    const dialog = ref<boolean>(false)
    const terminAm = ref<Date | null>(null)
    const isAngefragt = ref<boolean>(false)
    const isBestaetigt = ref<boolean>(false)
    const isAngefragtDisabled = computed(() => {
        return isBestaetigt.value
    })
    const isSubmitDisabled = computed(() => {
        const format = 'YYYYMMDDHHmm'

        return (
            dayjs(props.betriebspruefung.terminAm).format(format) ===
                dayjs(terminAm.value).format(format) &&
            props.betriebspruefung.isTerminAngefragt === isAngefragt.value &&
            props.betriebspruefung.isTerminBestaetigt === isBestaetigt.value
        )
    })
    const bgClass = computed(() => {
        const now = dayjs()
        const terminAm = dayjs(props.betriebspruefung.terminAm)
        if (!props.betriebspruefung.pruefungsergebnis && terminAm < now.startOf('month')) {
            return 'bg-red'
        }
        const endOfMonth = now.endOf('month')
        if (
            !props.betriebspruefung.pruefungsergebnis &&
            terminAm >= now.startOf('month') &&
            terminAm < endOfMonth
        ) {
            return 'bg-yellow'
        }
        if (
            !props.betriebspruefung.pruefungsergebnis &&
            terminAm >= endOfMonth &&
            terminAm < now.add(3, 'month').endOf('month')
        ) {
            return 'bg-green'
        }

        return ''
    })

    watch([isBestaetigt], () => {
        if (isBestaetigt.value) {
            isAngefragt.value = true
        }
    })

    onMounted(async () => {
        isAngefragt.value = props.betriebspruefung.isTerminAngefragt
        isBestaetigt.value = props.betriebspruefung.isTerminBestaetigt
        if (props.betriebspruefung.terminAm) {
            terminAm.value = new Date(props.betriebspruefung.terminAm)
        }
    })

    const handleTerminAmSubmit = async () => {
        if (terminAm.value) {
            const request: LegeBetriebspruefungsterminFestRequest = {
                betriebspruefung: props.betriebspruefung['@id'],
                terminAm: dayjs(terminAm.value).toISOString(),
                isTerminAngefragt: isAngefragt.value,
                isTerminBestaetigt: isBestaetigt.value
            }

            const result = await service.legeBetriebspruefungsterminFest(request)

            if (result) {
                emit('itemChanged', result)
                dialog.value = false
            }
        }
    }
</script>

<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ props: activatorProps }">
            <v-row :class="bgClass">
                <v-btn v-bind="activatorProps" append-icon="mdi-pencil" variant="text">{{
                    formatDateTimeNoSeconds(betriebspruefung.terminAm)
                }}</v-btn>
            </v-row>
        </template>
        <v-card prepend-icon="mdi-calendar" title="Termin ändern">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="12" sm="12">
                        <VueDatePicker
                            v-model="terminAm"
                            :week-numbers="{ type: 'iso' }"
                            placeholder="Termin wählen"
                            :clearable="false"
                            required
                            time-picker-inline
                            :minutes-increment="5"
                            locale="de"
                            :format-locale="de"
                            format="dd.LL.y HH:mm"
                            cancel-text="abbrechen"
                            select-text="auswählen"
                            :teleport="true"
                            six-weeks="center"
                            :action-row="{ showPreview: false }"
                        />
                    </v-col>

                    <v-col cols="12" md="4" sm="6">
                        <v-switch
                            v-model="isAngefragt"
                            :label="`Termin ist angefragt`"
                            :disabled="isAngefragtDisabled"
                            color="primary"
                            dense
                            hide-details
                        ></v-switch>
                    </v-col>

                    <v-col cols="12" md="4" sm="6">
                        <v-switch
                            v-model="isBestaetigt"
                            :label="`Termin ist bestätigt`"
                            color="primary"
                            dense
                            hide-details
                        ></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text="Abbrechen" variant="plain" @click="dialog = false"></v-btn>

                <v-btn
                    color="primary"
                    text="Speichern"
                    variant="tonal"
                    :disabled="isSubmitDisabled"
                    @click="handleTerminAmSubmit"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
