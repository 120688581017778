import type { BetriebsbeauftragteResponse, Betriebsbeauftragter } from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useBetriebsbeauftragterService = () => {
    const fetch = useFetch({ baseURL: '/api/betriebsbeauftragte' })

    const getAllBetriebsbeauftragte = (fetchParams: FetchParams = {}) => {
        return fetch.get<BetriebsbeauftragteResponse>({ fetchParams })
    }

    const getBetriebsbeauftragterByIri = (iri: Betriebsbeauftragter['@id']) => {
        return useFetch({ baseURL: iri ?? '' }).get<Betriebsbeauftragter>({})
    }

    const getBetriebsbeauftragterById = (id: string) => {
        return getBetriebsbeauftragterByIri(`/api/betriebsbeauftragte/${id}`)
    }

    return {
        getAllBetriebsbeauftragte,
        getBetriebsbeauftragterById,
        getBetriebsbeauftragterByIri
    }
}
