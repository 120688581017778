import type {
    BetriebspruefungenResponse,
    Betriebspruefung,
    LegeBetriebspruefungsterminFestRequest
} from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useBetriebspruefungService = () => {
    const fetch = useFetch({ baseURL: '/api/betriebspruefungen' })

    const getAllBetriebspruefungen = (fetchParams: FetchParams = {}) => {
        return fetch.get<BetriebspruefungenResponse>({ fetchParams })
    }

    const getBetriebspruefungById = (id: string) => {
        return getBetriebspruefungByIri(`/api/betriebspruefungen/${id}`)
    }

    const getBetriebspruefungByIri = (iri: Betriebspruefung['@id']) => {
        return useFetch({ baseURL: iri ?? '' }).get<Betriebspruefung>({})
    }

    const legeBetriebspruefungsterminFest = async (
        request: LegeBetriebspruefungsterminFestRequest
    ) => {
        return await useFetch({
            baseURL: '/api/betriebspruefungen/lege-betriebspruefungstermin-fest'
        }).post<Betriebspruefung>({ body: request })
    }

    return {
        getAllBetriebspruefungen,
        getBetriebspruefungById,
        legeBetriebspruefungsterminFest
    }
}
